<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'banners' }">
          Banner and Social Media</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="display-1 font-weight-bold my-5 text-capitalize">
        Add Banner and Social Media
      </div>

      <v-form @submit.prevent="submit" ref="form">
        <div class="row">
          <div class="col-md-5">
            <v-divider class="pb-5"></v-divider>
            <label class="text-field-label">Type</label>
            <v-select
              flat
              solo
              :items="type"
              item-text="label"
              item-value="value"
              v-model="form.type"
              :rules="rules.type"
              @change="selectType"
              :error-messages="notes"
            ></v-select>
            <br />
            <label class="text-field-label">Title</label>
            <v-text-field
              flat
              solo
              required
              counter
              class="mt-2"
              :rules="rules.title"
              maxlength="255"
              v-model="form.title"
              :error-messages="form.$getError('title')"
            ></v-text-field>

            <label class="text-field-label">Description</label>
            <v-textarea
              solo
              maxlength="255"
              name="input-7-4"
              v-model="form.description"
              placeholder="Description"
              :error-messages="form.$getError('description')"
            ></v-textarea>

            <label class="text-field-label">Url</label>
            <v-text-field
              flat
              solo
              required
              counter
              placeholder="Enter Url"
              v-model="form.url"
              :error-messages="form.$getError('url')"
            ></v-text-field>
          </div>
          <div class="col" />
          <div class="col-md-6">
            <label class="text-field-label">Enable</label>
            <v-switch v-model="form.status" inset></v-switch>
            <label class="text-field-label">Photo</label>
            <br />
            <br />
            <input v-model="form.image" hidden />
            <div class="col-md-9">
              <v-btn
                @click="document.getElementById('uploader').click()"
                color="secondary"
                class="mb-2"
                :disabled="isDisabled"
              >
                {{ photoCover ? 'Change' : 'Add' }} Photo
                <v-file-input
                  hide-input
                  prepend-icon=""
                  id="uploader"
                  @change="onFileChanged"
                ></v-file-input>
              </v-btn>
              <div class="col-md-9">
                <v-img
                  size="100%"
                  class="mb-4"
                  v-if="photoCover"
                  :src="photoCover"
                  :lazy-src="photoCover"
                ></v-img>
                <small v-if="form.$getError('image')" class="danger">{{
                  form.$getError('image')
                }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <v-btn type="submit" color="primary" :loading="form.$busy"
            >Submit</v-btn
          >
        </div>
      </v-form>
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import { mdiChevronLeft } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapState } from 'vuex'
import { each } from 'lodash'

export default {
  name: 'BannerCreate',

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      document,
      other: true,
      isDisabled: true,
      bannerType: '',
      notes: '',
      isSelecting: false,
      photoCover: null,
      showBannerUpload: false,
      showUpload: false,
      form: new Form({
        title: '',
        url: '',
        description: '',
        type: '',
        status: false,
        image: null,
      }),
      icons: {
        arrowLeft: mdiChevronLeft,
      },
      rules: {
        type: [(v) => !!v || 'Type is required'],
        title: [(v) => !!v || 'Name is required'],
      },
      type: [
        {
          label: 'Banner',
          value: 'banner',
        },
        {
          label: 'Social Media',
          value: 'social',
        },
      ],
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      bannerDetails: (state) => state.banner.bannerDetails,
    }),
  },

  methods: {
    ...mapActions({
      storeBanner: 'banner/storeBanner',
    }),

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      const formData = new FormData()

      each(this.form.$data(), (value, key) => {
        if (['status'].includes(key)) {
          let result = ''
          if (value === true) {
            result = 'enable'
          } else {
            result = 'disable'
          }
          formData.append(key, result)
        } else {
          formData.append(key, value)
        }
      })

      this.storeBanner(formData)
        .then(() => {
          this.form.$busy = false
          this.showSnackbar(
            'Banner And Social Media successfully created!',
            'success'
          )

          setTimeout(() => {
            this.$router.push({
              name: 'banners',
            })
          }, 1000)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
          this.form.$busy = false
        })
    },

    onFileChanged(file) {
      if (file) {
        this.show = true
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          this.photoCover = e.target.result
          let img = new Image()

          img.onload = () => {
            if (this.bannerType === 'banner') {
              if (img.width !== 828 || img.height !== 408) {
                this.showSnackbar(
                  'Your uploaded images do not meet the 828x408 pixel requirement.',
                  'error'
                )
                this.photoCover = null
                this.form.image = null
              } else {
                this.form.image = file
              }
            }
            if (this.bannerType === 'social') {
              if (img.width !== 828 || img.height !== 462) {
                this.showSnackbar(
                  'Your uploaded images do not meet the 828x462 pixel requirement.',
                  'error'
                )
                this.photoCover = null
                this.form.image = null
              } else {
                this.form.image = file
              }
            }
          }

          img.src = e.target.result
        }
      } else {
        this.photoCover = null
        this.form.image = null
      }
    },

    selectType(value) {
      this.isDisabled = false
      this.bannerType = value
      if (value === 'banner') {
        this.notes = 'Note the dimension for banner should be 828X408 pixels'
      } else {
        this.notes = 'Note the dimension for social should be 828X462 pixels'
      }
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },
}
</script>
